<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Flyer ID"
          v-validate="'required'"
          name="FlyerID"
          v-model="data.code"
          disabled
        />
        <span class="text-danger text-sm" v-show="errors.has('FlyerID')">{{
          errors.first("FlyerID")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <!-- <vs-textarea
          class="w-full"
          label="Description"
          v-validate="'required'"
          name="Description"
          v-model="data.description"
        /> -->
        <label for="">Description</label>
        <vue-editor v-model="data.description" />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          type="date"
          class="w-full"
          label="Valid From"
          v-validate="'required'"
          name="ValidFrom"
          :max="data.validTo"
          v-model="data.validFrom"
        />
        <span class="text-danger text-sm" v-show="errors.has('ValidFrom')">{{
          errors.first("ValidFrom")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          type="date"
          class="w-full"
          label="Valid To"
          v-validate="'required'"
          name="ValidTo"
          :min="data.validFrom"
          v-model="data.validTo"
        />
        <span class="text-danger text-sm" v-show="errors.has('ValidTo')">{{
          errors.first("ValidTo")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          v-if="id != null"
          type="file"
          class="w-full"
          label="Image"
          name="Image"
          v-model="data.image"
          @change="handleOnFileChange"
        />
        <vs-input
          v-else
          type="file"
          class="w-full"
          label="Image"
          name="Image"
          v-validate="'required'"
          v-model="data.image"
          @change="handleOnFileChange"
        />
        <span class="text-danger text-sm" v-show="errors.has('Image')">{{
          errors.first("Image")
        }}</span>
        <div
          class="p-2 rounded-lg border-2 mt-2"
          style="border: solid #ef4444; background-color: #fca5a5"
        >
          <ul class="text-xs">
            <li>Image size must be 800 x 300 pixel</li>
            <li>Image format must be .jpg, .jpeg or .png</li>
            <li>Image size must be less than 500 Kb</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <div id="preview">
          <img v-if="imagePreviewUrl" :src="imagePreviewUrl" />
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          code: this.generateCode(),
          description: "",
          validFrom: "",
          validTo: "",
          image: "",
        },
        imageFile: null,
        imagePreviewUrl: "",
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.postData();
        }
      });
    },
    paramData() {
      const formData = new FormData();
      // check this.id is null or not
      if (this.id != null) {
        formData.append("id", this.id);
      }
      formData.append("code", this.data.code);
      formData.append("description", this.data.description);
      formData.append("valid_from", this.data.validFrom);
      formData.append("valid_to", this.data.validTo);
      formData.append("image", this.imageFile);

      return formData;
    },
    generateCode() {
      // YYYYMMDDHHmmss
      let date = new Date();
      let code =
        date.getFullYear() +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        ("0" + date.getDate()).slice(-2) +
        ("0" + date.getHours()).slice(-2) +
        ("0" + date.getMinutes()).slice(-2) +
        ("0" + date.getSeconds()).slice(-2) +
        Math.random().toString(36).substring(2, 6).toUpperCase();

      return code;
    },
    postData() {
      this.$http
        .post(this.url, this.paramData(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    handleOnFileChange(e) {
      const file = e.target.files[0];
      // validate file upload
      if (!file.type.match("image.*")) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Invalid file type, please upload image file",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      this.imageFile = file;
      this.imagePreviewUrl = URL.createObjectURL(file);
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.id;
            this.data.code = resp.data.code;
            this.data.description = resp.data.description;
            this.data.validFrom = moment(resp.data.valid_from).format(
              "YYYY-MM-DD"
            );
            this.data.validTo = moment(resp.data.valid_to).format("YYYY-MM-DD");
            this.imagePreviewUrl = resp.data.image_url;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
