<template>
  <vx-card>
    <vs-tabs>
      <vs-tab label="Flyer">
        <div class="tab-text">
          <flyer></flyer>
        </div>
      </vs-tab>
      <vs-tab label="Flyer History">
        <div class="tab-text">
          <flyer-history></flyer-history>
        </div>
      </vs-tab>
      <vs-tab label="Flyer Allocation">
        <div class="tab-text">
          <flyer-territory></flyer-territory>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Flyer from "./flyer/index";
import FlyerTerritory from "./flyer-territory/index";
import FlyerHistory from "./flyer-history/index";

export default {
  components: {
    Flyer,
    FlyerTerritory,
    FlyerHistory,
  },
};
</script>
